@font-face {
  font-family: 'Archivo';
  src: url('./assets/fonts/Archivo/Archivo-VariableFont_wdth,wght.ttf');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
body .p-component {
  font-family: 'Archivo', sans-serif;
}

.background-image {
  background-image: url('/assets/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.bg-top-margin {
  display: flex;
  justify-content: end;
  height: calc(100vh - 100px);
}

@media (max-height: 699px) {
  .bg-top-margin {
    height: calc(100vh - 130px);
  }
}

@media (min-height: 700px) {
  .bg-top-margin {
    height: calc(100vh - 100px);
  }
}

@media (min-height: 700px) and (max-width: 991px) {
  .bg-top-margin {
    height: calc(100vh - 150px);
  }
}

.bg-margin-top-table {
  margin-top: 12rem;
}

// .bg-margin-top-table {
//  display: flex;
//  justify-content: flex-start;
//}
//
//@media (min-height: 700px) {
//  .bg-margin-top-table {
//    justify-content: space-around;
//  }
//}
//
//@media (max-height: 699px) {
//  .bg-margin-top-table {
//    justify-content: flex-end;
//  }
//}

.height-table {
  padding-top: 150px;
  height: calc(100vh - 250px);
}

.height-upsert {
  padding-top: 50px;
  height: calc(100vh - 250px);
}

.p-menuitem {
  background: rgba(4, 43, 67, 1);
  border-radius: 5px;
}

.p-menuitem-content {
  border-radius: 5px;
  color: white;
}

.p-menuitem-content:hover {
  border-radius: 5px;
}

.p-menuitem-link:hover {
  background: rgb(45, 77, 97);
  border-radius: 5px;
}

.p-menuitem-text:hover {
  color: black !important;
}

.p-menuitem-link .p-menuitem-text {
  color: darkgrey;
}

.logout-text .p-menuitem-text {
  color: white !important;
}

.logout-text .p-menuitem-icon {
  color: white;
}

.p-menuitem-icon:hover {
  color: black !important;
}

.p-menu {
  padding: 0;
}

.p-datatable {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  border: none;
}

.p-datatable-thead > tr:first-child > th:first-child {
  border-top-left-radius: 5px;
}

.p-datatable-thead > tr:first-child > th:last-child {
  border-top-right-radius: 5px;
}

.p-datatable-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 5px;
}

.p-datatable-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 5px;
}

.p-datatable th,
.p-datatable td {
  text-align: left;
  padding: 15px;
  font-size: 12px;
  font-weight: bold;
}

.p-datatable .p-datatable-thead > tr > th {
  background: rgba(38, 131, 190, 1);
  font-size: 12px;
  color: white;
  font-weight: normal;
}

.p-datatable .p-datatable-tbody > tr {
  background: rgba(234, 241, 246, 1);
}

.p-button-icon {
  font-size: 15px;
}

.p-datatable .p-datatable-thead > tr > th {
  position: sticky;
  //text-align: center;
}

.p-button.primary-custom {
  background: rgba(38, 131, 190, 1);
  font-size: 13px;
  border: none;
}

.p-button.secondary-custom {
  font-size: 13px;
  border: rgba(38, 131, 190, 1) 1px solid;
  color: rgba(38, 131, 190, 1);
  background: white;
}

.p-button.upload-custom {
  font-size: 13px;
  border: none;
  color: darkgrey !important;
  background: transparent;
  width: 3rem;
}

.p-button.language-custom {
  background: transparent;
  border: none;
  color: #ffffff;
}

.p-dialog-header-icon {
  color: white;
}

.p-dialog-title {
  color: white;
}

.p-dialog-content {
  padding: 2rem;
}

.p-fileupload-buttonbar {
  display: none;
}

.p-fileupload-content {
  border-radius: 5px 5px 0 0;
  border-style: dashed;
}

.custom-toast-error {
  border: none;
  font-size: 16px;
  box-shadow: 0 15px 15px rgba(191, 191, 191, 0.5);
}

.custom-toast-success {
  border: none;
  font-size: 16px;
  box-shadow: 0 15px 15px rgba(191, 191, 191, 0.5);
}

.p-tooltip {
  font-size: 12px;
}

.p-inputnumber-button {
  background: transparent;
  border: 1px solid #d1d5db;
}

//.p-button-icon-only.button-color {
//  color: red;
//}

.p-button-icon-only {
  color: rgba(38, 131, 190, 1) !important;
  background: white;
}

.float-label-always-active.p-float-label label {
  top: -1.5em;
  color: red;
}

.p-tabview-nav {
  align-items: center;
  justify-content: space-around;
  background: transparent;
}

.p-tabview-panels {
  background: transparent;
}

.p-menuitem-link {
  background: transparent;
  font-weight: normal;
}

.p-menuitem-text {
  color: black !important;
}

.p-button-icon-only {
  color: white;
}

.no-ripple .p-ripple {
  pointer-events: none;
  background: red;
}

.p-dialog-header {
  background: rgba(38, 131, 190, 1);
}

.custom-dialog-header .p-dialog-header {
  background: rgba(34, 81, 110, 1);
}

.custom-icon-autocomplete .p-button-icon-only {
  background: transparent;
  color: rgba(38, 131, 190, 1) !important;
  border: 1px solid #d1d5db;
  border-left: none;
  pointer-events: none;
}

.custom-icon-autocomplete .p-autocomplete-input {
  border-right: none;
}

.panel-empty .p-panel-header {
  background: rgba(137, 175, 199, 1);
}

.p-panel-header {
  background: rgba(38, 131, 190, 1);
}

.p-panel-content {
  background: rgba(234, 241, 246, 1);
}

.p-panel {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-accordion-header-link {
  background: rgba(38, 131, 190, 1);
  padding: 6px;
  border-radius: 0;
  color: white;
  font-size: 14px;
  align-items: center;
}

.p-accordion-header-link:hover {
  background: rgba(38, 131, 190, 1);
}

.p-accordion-content {
  background: rgb(235 241 246);
  border: none;
}

.p-panel-content {
  padding: 3px 0;
  height: 300px;
  overflow-y: auto;
}

.p-panel {
}

.p-accordion-tab {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.p-slider {
  height: 25px;
  background: rgba(234, 241, 246, 1);
}

.p-slider-range {
  height: 25px;
  background: rgba(38, 131, 190, 1);
  border-radius: 6px;
}

.p-slider-handle {
  display: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.p-button.arrow-button {
  padding: 0.25rem 0.5rem;
  width: 1.5rem;
  border: none;
  background-color: #2683be;
  color: white !important;
}

.p-button.arrow-button .pi {
  font-size: 1rem;
}

.p-tabview-panels {
  padding: 0;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: white;
  margin-top: 5px;
  margin-bottom: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(137, 175, 199, 1);
  border-radius: 20px;
  border: 3px solid white;
}

.graph-dropdown.p-dropdown {
  background: transparent;
  width: 15rem;
  border: rgba(38, 131, 190, 1) 1px solid;
}
